import styled from 'styled-components'
import { toRem } from 'theme/utils'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: ${(props) => toRem(props.margin || 0)};
  padding: ${(props) => toRem(props.padding || 0)};
`

export const Content = styled.div`
  overflow: ${(props) => (!props.isOpen ? 'hidden' : 'visible')};
  height: ${(props) => (!props.isOpen ? '0' : 'auto')};
  margin: ${(props) => toRem(props.margin || 0)};
  position: ${(props) => props.position};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => toRem(props.marginTop || 0)};
  margin-bottom: ${(props) => toRem(props.marginBottom || 0)};
  padding: ${(props) => toRem(props.paddingHeader || 0)};
  cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'default')};

  svg {
    max-width: 13px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    svg {
      max-width: initial;
    }
  }
`

export const Title = styled.h2`
  ${(props) => props.theme.font(props.fontSize ? props.fontSize : 16, 16, 700)};
  flex: 1;
  color: ${(props) => props.theme.colors.black(800)};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  padding-left: ${({ iconDistance }) => toRem(iconDistance ? iconDistance : 5)};
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    ${(props) =>
      props.theme.font(props.fontSize ? props.fontSize : 24, 24, 700)};
  }
`

export const CollapseButton = styled.div`
  cursor: pointer;
  transform: ${(props) => (!props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 175ms;
`
