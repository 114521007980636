import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white(500)};
`
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`

export const Label = styled.label`
  ${({ theme }) => theme.font(14, 18, 400)};
  color: ${({ theme }) => theme.colors.black(700)};
`
