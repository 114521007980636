import { Meta } from 'types/catalog'

export enum HighlightedFilters {
  STAMPS = 'highlighted_stamp',
  FREE_SHIPPING = 'highlighted_free_shipping',
  FLASH_SHIPPING = 'highlighted_flash',
  HAS_OPEN_BOX = 'has_open_box',
  HAS_PRIME = 'has_prime'
}

export enum FilterTypes {
  PRICE = 'price',
  MANUFACTURER = 'manufacturer',
  CATEGORY = 'category',
  HAS_FLASH = 'has_flash',
  KABUM_PRODUCT = 'kabum_product',
  HAS_OFFER = 'has_offer'
}

export type FilterProps = {
  metaFilters: Meta
  setIsFiltersOpen?: (isFiltersOpen: boolean) => void
  cookieIsMobile: boolean
}
