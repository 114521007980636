import {
  IconJustArrived,
  IconOpenBox,
  IconPrimeShuriken,
  IconTruck
} from '@k_frontend/icons'
import theme from 'theme'

import { HighlightedFilters } from './Filter.types'

export const HIGHLIGHTED_FILTERS = [
  {
    label: 'Frete grátis',
    type: HighlightedFilters.FREE_SHIPPING,
    icon: (
      <IconTruck
        color={theme.colors.secondary(500)}
        width={theme.rem(16)}
        height={theme.rem(16)}
      />
    )
  },
  {
    label: 'Entrega Flash',
    type: HighlightedFilters.FLASH_SHIPPING,
    icon: (
      <IconJustArrived
        color={theme.colors.secondary(500)}
        width={theme.rem(16)}
        height={theme.rem(16)}
      />
    )
  },
  {
    label: 'OpenBox',
    type: HighlightedFilters.HAS_OPEN_BOX,
    icon: (
      <IconOpenBox
        color={theme.colors.secondary(500)}
        width={theme.rem(16)}
        height={theme.rem(16)}
      />
    )
  },
  {
    label: 'Prime Ninja',
    type: HighlightedFilters.HAS_PRIME,
    icon: (
      <IconPrimeShuriken
        color={theme.colors.secondary(500)}
        width={theme.rem(16)}
        height={theme.rem(16)}
      />
    )
  }
]
