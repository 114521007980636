import IconClose from 'components/Icons/IconClose'

import * as S from './SelectedFilters.styles'

function SelectedFilters() {
  const selectedFilters = []

  return (
    selectedFilters.length > 0 && (
      <S.SelectedFilters>
        {selectedFilters.map((filter) => (
          <S.Filter>
            {filter}
            <IconClose />
          </S.Filter>
        ))}
      </S.SelectedFilters>
    )
  )
}

export default SelectedFilters
