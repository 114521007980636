import { useState } from 'react'

import { KABUM_URL } from 'settings/kernel'

import * as S from './Categories.styles'
import { CategoriesProps } from './Categories.types'

function Categories({ categories }: CategoriesProps) {
  const [showMore, setShowMore] = useState(false)
  const { list, basePath, relatedCategory, asideTitle } = categories || {}

  const baseUrl = !!basePath ? `${KABUM_URL}/${basePath}` : KABUM_URL
  const title = asideTitle || 'Categorias relacionadas'

  if (list?.length > 0) {
    return (
      <S.Container id="blocoCategoriasListagem">
        <S.Title>{title}</S.Title>
        {relatedCategory && <S.ListTitle>{relatedCategory}</S.ListTitle>}
        <S.List showMore={showMore}>
          {list?.map((item) => (
            <S.Item className="linkCategoriaListagem" key={item.code}>
              <S.ItemText href={`${baseUrl}/${item.slug}`}>
                {item.name}
              </S.ItemText>
            </S.Item>
          ))}

          {list?.length > 5 && (
            <S.ButtonShowMore onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Ver menos' : 'Ver mais'}
            </S.ButtonShowMore>
          )}
        </S.List>
      </S.Container>
    )
  } else {
    return null
  }
}

export default Categories
