import { Switch } from '@k_frontend/ui'

import * as S from './HighlightedFilter.styles'
import { HighlightedFilterProps } from './HighlightedFilter.types'
function HighlightedFilter({
  icon,
  label,
  checked,
  onClick
}: HighlightedFilterProps) {
  return (
    <S.Wrapper onClick={() => onClick()}>
      <S.LabelWrapper>
        {icon}
        <S.Label>{label}</S.Label>
      </S.LabelWrapper>

      <Switch variant="md" checked={checked} category="secondary" />
    </S.Wrapper>
  )
}

export default HighlightedFilter
