export const limitOptions = {
  catalog: [
    {
      value: '20',
      label: '20 por página'
    },
    {
      value: '40',
      label: '40 por página'
    },
    {
      value: '60',
      label: '60 por página'
    },
    {
      value: '80',
      label: '80 por página'
    },
    {
      value: '100',
      label: '100 por página'
    }
  ],
  smarthint: [
    {
      value: '20',
      label: '20 por página'
    },
    {
      value: '40',
      label: '40 por página'
    }
  ]
}
