import { Fragment, useEffect, useMemo, useState } from 'react'
import Pagination from 'react-paginate'

import Image from 'next/image'

import { useWishlist } from '@k_frontend/core'
import { IconGrid, IconListing } from '@k_frontend/icons'
import Limit from 'components/PageCatalog/Limit'
import SelectedFilters from 'components/PageCatalog/SelectedFilters'
import Sort from 'components/PageCatalog/Sort'
import EmptyMessage from 'components/PageCatalogEmpty/EmptyMessage'
import ProductGrid from 'components/Product/ProductGrid'
import ProductList from 'components/Product/ProductList'
import Section from 'components/Section'
import { useCatalog } from 'hooks/useCatalog'
import { useListingView } from 'hooks/useListingView'
import theme from 'theme'
import { FilterType, type Product } from 'types/catalog'

import { ResizeHOC } from 'components/ResizeHOC'
import { useDebouncedCallback } from 'hooks/useDebouncedCallback'
import { tailwindTheme } from 'theme/tailwindTheme'
import AsideFiltersMobile from '../AsideFilters/Mobile'
import QuickCategoryLinks from '../QuickCategoryLinks'
import {
  ProductCountLoading,
  ProductGridLoading,
  ProductListLoading
} from './Products.loading'
import * as S from './Products.styles'
import { DeviceEnum, type ProductsProps } from './Products.types'

export default function Products({
  onProductClick,
  descriptions,
  catalog,
  loadingProducts,
  productPathLink,
  faq,
  renderBreadcrumb,
  bannerFooter,
  cookieIsMobile,
  isLoggedIn
}: ProductsProps) {
  const wishlist = useWishlist((state) => state.wishlist)
  const { setDebouncedCallback } = useDebouncedCallback()
  const { view, setView } = useListingView()
  const { params, setParams } = useCatalog()
  const showFaq = faq?.length > 0
  const locationUrl =
    typeof window !== 'undefined' ? window.location.pathname : ''

  const { data, meta, pagination } = catalog
  const [currentPage, setCurrentPage] = useState(pagination.current)

  useEffect(() => {
    setCurrentPage(pagination.current)
  }, [pagination.current])

  function onChangeLimit(page_size: number) {
    setParams({ ...params, page_size })
  }

  function onChangeSort(sort: string) {
    setParams({ ...params, sort })
  }

  function setPage(page: number) {
    setCurrentPage(page)
    setDebouncedCallback(() => {
      setParams(
        {
          ...params,
          page_number: page
        },
        FilterType.PAGINATION
      )
    })
  }

  function showNextPage() {
    return pagination.current !== pagination.total
  }

  function showPrevPage() {
    return pagination.current !== 1
  }

  function getFaqStructuredData() {
    const linkedData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faq?.map((item) => ({
        '@type': 'Question',
        name: item?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item?.answer
        }
      }))
    }

    return (
      <script type='application/ld+json'>{JSON.stringify(linkedData)}</script>
    )
  }

  function renderToggleView() {
    return (
      <S.ToggleView>
        <S.Listing>
          <button
            type='button'
            onClick={() => setView('LISTING')}
            title='Visualizar em Lista'
          >
            <IconListing
              color={
                view === 'LISTING'
                  ? theme.colors.secondary(500)
                  : theme.colors.black(700)
              }
            />
          </button>
        </S.Listing>
        <div>
          <button
            type='button'
            onClick={() => setView('GRID')}
            title='Visualizar em Grid'
          >
            <IconGrid
              color={
                view === 'GRID'
                  ? theme.colors.secondary(500)
                  : theme.colors.black(700)
              }
            />
          </button>
        </div>
      </S.ToggleView>
    )
  }

  function renderBannerFooter(device: DeviceEnum) {
    const propsByDevice = {
      [DeviceEnum.MOBILE]: {
        width: 736,
        height: 128,
        src: bannerFooter?.bannerMobile
      },
      [DeviceEnum.DESKTOP]: {
        width: 1248,
        height: 132,
        src: bannerFooter?.banner
      }
    }

    const { width, height, src } = propsByDevice[device]

    if (!src) return null

    const hasLink = !!bannerFooter?.link

    return (
      <S.BannerFooter
        as={hasLink ? 'a' : 'div'}
        href={bannerFooter?.link}
        target={bannerFooter?.target}
      >
        <Image
          layout='responsive'
          src={src}
          alt='Banner footer'
          width={width}
          height={height}
          quality={100}
          priority
        />
      </S.BannerFooter>
    )
  }

  function renderProductListing() {
    return loadingProducts ? (
      <ProductListLoading />
    ) : (
      <S.ProductsList>
        {data.map((product: Product, index) => {
          product.position = index + 1
          product.list = 'catálogo'
          return (
            <ProductList
              key={index}
              product={product}
              productPathLink={productPathLink}
              fnOnProductClick={onProductClick}
            />
          )
        })}
      </S.ProductsList>
    )
  }

  function renderProductGrid() {
    return loadingProducts ? (
      <ProductGridLoading />
    ) : (
      <S.ProductsGrid>
        {data.map((product: Product, index) => {
          product.position = index + 1
          product.list = 'catálogo'
          return (
            <ProductGrid
              key={index}
              product={product}
              productPathLink={productPathLink}
              fnOnProductClick={onProductClick}
              isPageList
              cookieIsMobile={cookieIsMobile}
            />
          )
        })}
      </S.ProductsGrid>
    )
  }

  function renderPagination(isMobile: boolean) {
    return (
      <Pagination
        previousLabel={showPrevPage() && '<'}
        nextLabel={showNextPage() && '>'}
        forcePage={currentPage - 1}
        pageCount={pagination.total}
        pageRangeDisplayed={isMobile ? 4 : 2}
        marginPagesDisplayed={isMobile ? 0 : 5}
        onPageChange={(e: { selected: number }) => setPage(e.selected + 1)}
        containerClassName='pagination'
        activeLinkClassName='active'
        pageLinkClassName='page'
        nextLinkClassName='nextLink'
        previousLinkClassName='prevLink'
        breakLinkClassName='break'
      />
    )
  }

  return (
    <>
      <S.ProductsWrapper>
        <S.SortWrapper id='listingOrdenation'>
          <S.Filters id='Filter'>
            <S.OpenFilters>
              <AsideFiltersMobile
                slug={locationUrl.replace(/^\/+/, '')}
                isLoggedIn={isLoggedIn}
                cookieIsMobile={cookieIsMobile}
              />
            </S.OpenFilters>

            <Sort onChangeSort={onChangeSort} cookieIsMobile={cookieIsMobile} />

            <S.Limit>
              Exibir:
              <Limit onChangeLimit={onChangeLimit} />
            </S.Limit>

            {loadingProducts ? (
              <ProductCountLoading />
            ) : (
              <S.CatalogCount id='listingCount'>
                <b>{meta.totalItemsCount}</b>{' '}
                {meta.totalItemsCount > 1 ? 'produtos' : 'produto'}
              </S.CatalogCount>
            )}

            <ResizeHOC
              minDesktopWidth={tailwindTheme.screens.tablet}
              DesktopComponent={() => (
                <S.Toggle id='Toggle'>{renderToggleView()}</S.Toggle>
              )}
              MobileComponent={() => null}
              isMobileScreen={cookieIsMobile}
            />
          </S.Filters>
          <ResizeHOC
            DesktopComponent={() => null}
            MobileComponent={() => (
              <S.Breadcrumb id='Breadcrumb'>
                <div>{renderBreadcrumb()}</div>
              </S.Breadcrumb>
            )}
            isMobileScreen={cookieIsMobile}
          />
        </S.SortWrapper>

        <QuickCategoryLinks />

        {data?.length < 1 && <EmptyMessage />}
        <SelectedFilters />
        {useMemo(
          () =>
            view === 'LISTING' ? (
              <ResizeHOC
                minDesktopWidth={tailwindTheme.screens.tablet}
                DesktopComponent={renderProductListing}
                MobileComponent={renderProductGrid}
                isMobileScreen={cookieIsMobile}
              />
            ) : (
              renderProductGrid()
            ),
          [data, wishlist, view, cookieIsMobile]
        )}
        {useMemo(
          () => (
            <S.Pagination id='listingPagination'>
              {showPrevPage() && (
                <S.Page onClick={() => setPage(1)}>{'<<'}</S.Page>
              )}
              <ResizeHOC
                minDesktopWidth={tailwindTheme.screens.tablet}
                MobileComponent={() => renderPagination(true)}
                DesktopComponent={() => renderPagination(false)}
                isMobileScreen={cookieIsMobile}
              />
              {showNextPage() && (
                <S.Page onClick={() => setPage(pagination.total)}>
                  {'>>'}
                </S.Page>
              )}
            </S.Pagination>
          ),
          [pagination, params]
        )}
        <ResizeHOC
          MobileComponent={() => renderBannerFooter(DeviceEnum.MOBILE)}
          DesktopComponent={() => renderBannerFooter(DeviceEnum.DESKTOP)}
          isMobileScreen={cookieIsMobile}
        />
        <S.ListingDescriptions>
          {descriptions
            ?.filter((item) => item?.active === 1)
            ?.sort((a, b) => a?.position - b?.position)
            ?.map((item) => (
              <Fragment key={item?.code}>
                <div dangerouslySetInnerHTML={{ __html: item?.content }} />
              </Fragment>
            ))}
        </S.ListingDescriptions>
        {showFaq && (
          <>
            <S.ListingFaq>
              {faq?.map((item, index) => (
                <S.FaqItem key={index}>
                  <Section collapsible title={item.question} opened={false}>
                    <S.FaqItemContent
                      dangerouslySetInnerHTML={{
                        __html: item.answer
                      }}
                    />
                  </Section>
                </S.FaqItem>
              ))}
            </S.ListingFaq>
            {getFaqStructuredData()}
          </>
        )}
      </S.ProductsWrapper>
    </>
  )
}
