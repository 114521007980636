import { ChangeEvent, useState } from 'react'

import { currency } from 'utils/functions'

import * as S from './SliderFilter.styles'
import { SliderFilterProps } from './SliderFilter.types'

export default function SliderFilter({
  name,
  min,
  max,
  defaultValues,
  onChange
}: SliderFilterProps) {
  const [minValue, setMinValue] = useState(defaultValues[0])
  const [maxValue, setMaxValue] = useState(defaultValues[1])

  function handleInput(
    event: ChangeEvent<HTMLInputElement>,
    position: 'min' | 'max'
  ) {
    const { target } = event

    const onlyNumbersValue = Number(target.value.replace(/\D/g, ''))

    if (onlyNumbersValue > max) return

    let comparationValue
    let setFunction

    if (position === 'min') {
      comparationValue = minValue
      setFunction = setMinValue
    } else {
      comparationValue = maxValue
      setFunction = setMaxValue
    }

    comparationValue === onlyNumbersValue
      ? setFunction(Number(String(onlyNumbersValue).slice(0, -1)))
      : setFunction(onlyNumbersValue)
  }

  function handleValueChange(values: number[]) {
    setMinValue(values[0])
    setMaxValue(values[1])
  }

  function renderValue(value: number, decimal?: number) {
    return name === 'discount' ? `${value}%` : currency(value, decimal)
  }

  function handelOnBlurInputs() {
    const valuesReSorted = validateInputsMinMaxValues()
    onChange(valuesReSorted)
  }

  function validateInputsMinMaxValues(): number[] {
    if (minValue > maxValue) {
      setMaxValue(minValue)
      setMinValue(maxValue)
      return [maxValue, minValue]
    }

    return [minValue, maxValue]
  }

  return (
    <>
      <S.Root
        min={min}
        max={max}
        defaultValue={[minValue, maxValue]}
        value={[minValue, maxValue]}
        onValueChange={handleValueChange}
        onValueCommit={onChange}
      >
        <S.Track>
          <S.Range />
        </S.Track>
        <S.Thumb />
        <S.Thumb />
      </S.Root>

      <S.WrapperLabelValues>
        <S.LabelValue>{renderValue(minValue)}</S.LabelValue>
        <S.LabelValue>{renderValue(maxValue)}</S.LabelValue>
      </S.WrapperLabelValues>

      <S.Form>
        <S.InputRangePrice
          id='minPrice'
          type='text'
          label='Mínimo'
          value={renderValue(Math.floor(minValue), 0)}
          onChange={(e) => handleInput(e, 'min')}
          onBlur={handelOnBlurInputs}
        />
        <S.Separator>-</S.Separator>
        <S.InputRangePrice
          id='maxPrice'
          type='text'
          label='Máximo'
          value={renderValue(Math.ceil(maxValue), 0)}
          onChange={(e) => handleInput(e, 'max')}
          onBlur={handelOnBlurInputs}
        />
      </S.Form>
    </>
  )
}
