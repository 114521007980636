import { useState, useEffect } from 'react'

import Section from 'components/Section/view'

export default function SectionController(props) {
  const {
    id = '',
    icon,
    opened,
    title,
    collapsible,
    children,
    uppercase,
    fontSize,
    onOpen = () => {},
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(opened || false)

  useEffect(() => {
    if (isOpen) {
      onOpen()
    }
  }, [isOpen])

  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
    <Section
      id={id}
      Icon={icon}
      title={title}
      collapsible={collapsible}
      isOpen={isOpen}
      uppercase={uppercase}
      fontSize={fontSize}
      toggle={toggle}
      {...rest}
    >
      {children}
    </Section>
  )
}
