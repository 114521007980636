import styled from 'styled-components'

export const Sort = styled.select`
  flex: 1;
  ${({ theme }) => theme.font(14, 18, 400)};
  width: 100%;
  background-color: ${(props) => props.theme.colors.white(500)};
  background-repeat: no-repeat;
  justify-content: space-between;
  background-position: center right ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm}
    ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.black(800)};

  /* remove seta padrão */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    width: 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC2SURBVHgBpc8xCsIwFAbg/6Xo7OjWdtDBySNEUXGokCPoSexRvIKDk2DxBK4ioj1CRgfbZ1tRUjWl4D9kCPn+vAdkkVK1UDPmWyHHs7loJgc5VV4dSI17JCdBWGDB7ALsiTSJqgpekAj9pwGc6+W09/0uQFDErNxObx2fj9oGwbzabTeLAudHVYEN5iHzh+EoWEIgzK7jVDgD3KBt8At/FjCztsH32GZKKxDaNvgTlwrAsQ3+nQeY02Y0uvQN4QAAAABJRU5ErkJggg==');
    padding: ${({ theme }) => theme.spacing.xs}
      ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xs}
      ${({ theme }) => theme.spacing.sm};
    margin-left: ${({ theme }) => theme.spacing.xxs};
    border-radius: ${(props) => props.theme.borderRadius.md};
  }
`
export const Container = styled.div`
  display: flex;
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.xxs};

  .IconSort {
    top: 50%;
    left: ${({ theme }) => theme.spacing.xs};
    position: absolute;
    transform: translate(0, -50%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    max-width: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.black(800)};
    margin-left: 0;

    .IconSort {
      position: initial;
      transform: translate(0);
    }
  }
`

export const Ordination = styled.div`
  ${({ theme }) => theme.font(14, 18, 700)};
  color: ${(props) => props.theme.colors.black(800)};
  margin-left: ${({ theme }) => theme.spacing.xxs};
`
