import IconCollapseHeader from 'components/Icons/IconCollapseHeader'

import * as S from './styles'

export default function Section(props) {
  const {
    id,
    Icon,
    title,
    children,
    collapsible,
    isOpen,
    toggle,
    iconDistance,
    fontSize,
    padding,
    paddingHeader,
    marginTop,
    marginBottom,
    margin,
    position,
    uppercase = false
  } = props

  return (
    <S.Container id={id} padding={padding} margin={margin}>
      <S.Header
        paddingHeader={paddingHeader}
        marginTop={marginTop}
        marginBottom={marginBottom}
        collapsible={collapsible}
        onClick={collapsible ? toggle : null}
      >
        {Icon && <Icon />}

        <S.Title
          iconDistance={iconDistance}
          fontSize={fontSize}
          uppercase={uppercase}
        >
          {title}
        </S.Title>
        {collapsible && (
          <S.CollapseButton isOpen={isOpen} onClick={toggle}>
            <IconCollapseHeader />
          </S.CollapseButton>
        )}
      </S.Header>
      <S.Content isOpen={isOpen} margin={margin} position={position}>
        {children}
      </S.Content>
    </S.Container>
  )
}
