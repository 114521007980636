import { useUser } from '@k_frontend/core'
import { IconFilter } from '@k_frontend/icons'
import { useCatalog } from 'hooks/useCatalog'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from 'react-query'
import { useSwipeable } from 'react-swipeable'
import {
  brandShowcase,
  highlights,
  productsByCategory,
  promotion,
  releases,
  search,
  showcase
} from 'services/catalog'

import { SrOnly } from '@k_frontend/ui'
import { makeRemoteLoadSearchFactory } from 'factories'
import { ListingTypes } from 'types/catalog'
import * as S from './AsideFiltersMobile.styles'
import { AsideFiltersMobileProps } from './AsideFiltersMobile.types'

const Filter = dynamic(import('components/PageCatalog/Filter'), {
  loading: () => (
    <S.ContainerSkeleton>
      <Skeleton count={10} />
    </S.ContainerSkeleton>
  ),
  ssr: false
})

export default function AsideFiltersMobile({
  slug,
  isLoggedIn,
  cookieIsMobile
}: AsideFiltersMobileProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { clientId, session } = useUser()
  const { params: hookParams, listingType } = useCatalog()

  const paramsWithoutProducts = {
    ...hookParams,
    payload_data: 'only_filters'
  }
  const headers = {
    'client-id': clientId,
    session
  }

  const functionByType = {
    [ListingTypes.SEARCH]: () =>
      makeRemoteLoadSearchFactory().load(headers, paramsWithoutProducts),
    [ListingTypes.JUST_ARRIVED]: () => search(paramsWithoutProducts, headers),
    [ListingTypes.MOST_SEARCHED]: () => search(paramsWithoutProducts, headers),
    [ListingTypes.PROMOTION]: () =>
      promotion(slug.match(/[^\/]+$/)[0], paramsWithoutProducts),
    [ListingTypes.RELEASES]: () => releases(paramsWithoutProducts),
    [ListingTypes.HIGHLIGHTS]: () => highlights(paramsWithoutProducts),
    [ListingTypes.SHOWCASE]: () => showcase(paramsWithoutProducts),
    [ListingTypes.BRAND_SHOWCASE]: () => brandShowcase(paramsWithoutProducts),
    [ListingTypes.STORE_SHOWCASE]: () => brandShowcase(paramsWithoutProducts),
    [ListingTypes.BRAND_PAGE]: () => search(paramsWithoutProducts, headers),
    [ListingTypes.STORE_PAGE]: () => brandShowcase(paramsWithoutProducts),
    [ListingTypes.LISTING]: () =>
      productsByCategory(slug, paramsWithoutProducts)
  }

  const {
    data: filtersData,
    isLoading: isLoadingFiltersData,
    isSuccess: isSuccessFiltersData
  } = useQuery({
    queryKey: ['catalogFilters', slug],
    queryFn: () => functionByType[listingType](),
    staleTime: Infinity,
    enabled: isOpen
  })

  useEffect(() => {
    const html = document?.querySelector('html')
    const body = document?.querySelector('body')
    const filterElement = document?.querySelector(
      '#listingAsideMobile'
    ) as HTMLElement

    if (html?.style) {
      html.style.overflow = isOpen ? 'hidden' : 'unset'
    }
    if (body?.style) {
      html.style.overflow = isOpen ? 'hidden' : 'unset'
    }
    if (filterElement?.style) {
      filterElement.style.position = isOpen ? 'fixed' : 'relative'
    }

    return () => {
      if (html?.style) {
        html.style.overflow = 'unset'
      }
      if (body?.style) {
        html.style.overflow = 'unset'
      }
      if (filterElement?.style) {
        filterElement.style.position = 'absolute'
      }
    }
  }, [isOpen])

  function handleClickFilter() {
    setIsOpen(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handlers = useSwipeable({
    onSwipedLeft: ({ event }) => {
      const element = event.target as HTMLElement
      const isPriceRange =
        element.parentElement.classList.contains('priceRangeFilter')

      if (!isPriceRange) setIsOpen(false)
    },
    touchEventOptions: { passive: true }
  })

  return (
    <>
      <S.FilterButton onClick={handleClickFilter} {...handlers}>
        <IconFilter />
        Filtro
      </S.FilterButton>
      {isOpen && (
        <>
          <S.ContainerAside id='listingAsideMobile' showAddresses={isLoggedIn}>
            {isLoadingFiltersData && (
              <>
                <SrOnly>Carregando lista de filtros</SrOnly>
                <S.ContainerSkeleton>
                  <Skeleton count={10} />
                </S.ContainerSkeleton>
              </>
            )}
            {isSuccessFiltersData && (
              <Filter
                metaFilters={filtersData?.meta}
                setIsFiltersOpen={setIsOpen}
                cookieIsMobile={cookieIsMobile}
              />
            )}
          </S.ContainerAside>
          <S.BackgroundContainerAside
            onClick={() => {
              setIsOpen(false)
            }}
          />
        </>
      )}
    </>
  )
}
