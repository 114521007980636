import React from 'react'

import { Faq, Seo } from 'components/PageCatalog/Categories/Categories.types'
import { Catalog, Product } from 'types/catalog'

import { BannerFooter } from '../PageCatalog.types'

export type ProductsProps = {
  descriptions?: Seo[]
  faq?: Faq[]
  onProductClick: (product: Product) => void
  catalog: Catalog
  loadingProducts?: boolean
  productPathLink?: string
  renderBreadcrumb: () => React.ReactElement
  bannerFooter?: BannerFooter
  cookieIsMobile: boolean
  isLoggedIn: boolean
}

export enum DeviceEnum {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}
