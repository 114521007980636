import { useQuery } from 'react-query'

import { useRouter } from 'next/router'

import { getCategories, pushToDataLayer } from '@k_frontend/core'
import { IconCategories } from '@k_frontend/icons'
import Kernel from 'settings/kernel'

import { QuickCategoryLinksLoading } from './QuickCategoryLinks.loading'
import * as S from './QuickCategoryLinks.styles'
import { CategoriesList, List } from './QuickCategoryLinks.types'

export default function QuickCategoryLinks() {
  const { query } = useRouter()
  const categories = query?.categoria as string[]

  const { data, isLoading } = useQuery<CategoriesList>(
    ['getCategoriesList'],
    () => getCategories(getPathWithoutParams(), Kernel)
  )

  function getPathWithoutParams() {
    return categories?.join('/')
  }

  function getHighestCategoryLevel() {
    return query?.categoria[0]
  }

  function sortCategoriesList() {
    return data?.list.sort((a, b) => {
      return a.name.trim().localeCompare(b.name.trim(), undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    })
  }

  function handleDataLayer(category?: List) {
    const position = sortCategoriesList()?.indexOf(category) + 2 || 1
    pushToDataLayer({
      event: 'subCategoria',
      eventCategory: category?.name || 'Todos',
      position
    })
  }

  function renderList() {
    if (isLoading) {
      return <QuickCategoryLinksLoading />
    }

    return (
      <S.WrapperLinks>
        <S.ListContent>
          <S.LinkItem
            filled
            href={`/${getHighestCategoryLevel()}`}
            onClick={() => handleDataLayer()}
          >
            TODOS
          </S.LinkItem>
          {sortCategoriesList()?.map((category) => (
            <S.LinkItem
              key={category.code}
              href={`/${getPathWithoutParams()}/${category.slug}`}
              onClick={() => handleDataLayer(category)}
            >
              {category.name}
            </S.LinkItem>
          ))}
        </S.ListContent>
      </S.WrapperLinks>
    )
  }

  if (!categories?.length) return null

  return (
    <S.Container>
      <S.Title>
        <IconCategories width={16} />
        Categorias
      </S.Title>
      {renderList()}
    </S.Container>
  )
}
