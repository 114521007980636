import { ChangeEvent, useState, Fragment } from 'react'

import { dataLayerEventValueInterval } from '@k_frontend/core'
import { IconSearch } from '@k_frontend/icons'
import Toggle from 'components/Toggle'
import theme from 'theme'

import { FilterTypes } from '../Filter/Filter.types'
import * as S from './FilterItem.styles'
import { FilterItemProps } from './FilterItem.types'
import SliderFilter from './SliderFilter'

function FilterItem({
  filter,
  type,
  onSelectFilterPrice,
  onSelectFilterProductType,
  onSelectFilterDefault,
  toggle
}: FilterItemProps) {
  const { name, subtitle, min, max, values = [] } = filter

  const [tmpMin] = useState(filter.selectedMin || min)
  const [tmpMax] = useState(filter.selectedMax || max)
  const [firstOptions, setFirstOptions] = useState(values.slice(0, 5))
  const [remainingOptions, setRemainingOptions] = useState(values.slice(5))

  const hasRemainingOptionsSelect = remainingOptions.find(
    (options) => options.selected
  )
  const [showingRemaining, setshowingRemaining] = useState(
    Boolean(hasRemainingOptionsSelect)
  )

  function onAfterChangeInputRange(data: number[]) {
    const [onChangeMin, onChangeMax] = data
    onSelectFilterPrice(onChangeMin, onChangeMax)
    dataLayerEventValueInterval(
      'categorias-intervalo-preco',
      onChangeMin,
      onChangeMax
    )
  }

  function handleOnChangeProductTypeFilter(e: ChangeEvent<HTMLInputElement>) {
    if (onSelectFilterProductType) {
      const { checked, value } = e.target
      onSelectFilterProductType(value, checked)
      return
    }

    if (onSelectFilterDefault) {
      const { value } = e.target
      onSelectFilterDefault(type, value)
    }
  }

  function handleSearchBrands(text: string) {
    const filtered = values.filter((item) =>
      item.label.toLowerCase().includes(text.toLowerCase())
    )
    setFirstOptions(filtered.slice(0, 5))
    setRemainingOptions(filtered.slice(5))
  }

  function renderSearchBrands() {
    if (type === FilterTypes.MANUFACTURER)
      return (
        <>
          <S.SearchBrands>
            <IconSearch color={theme.colors.black(800)} />
            <S.InputBrands
              type="text"
              placeholder="Buscar marca"
              onChange={(event) => handleSearchBrands(event.target.value)}
            />
          </S.SearchBrands>
        </>
      )
  }

  return (
    <S.FilterItem
      className="filterItem"
      data-ic-section="filtros-categoria"
      open
    >
      <summary
        className="filterHeader"
        data-ic-title="filtros-categoria"
        data-testid="summaryFilter"
      >
        {name}
      </summary>

      <S.FilterItemOptions className="filterContent">
        {subtitle && (
          <S.FilterItemSubtitle className="filterContentSubtitle">
            {subtitle}
          </S.FilterItemSubtitle>
        )}

        {min >= 0 && max > 0 && (
          <SliderFilter
            name="price"
            min={Number(min)}
            max={Number(max)}
            defaultValues={[tmpMin, tmpMax]}
            onChange={onAfterChangeInputRange}
          />
        )}

        {renderSearchBrands()}

        {firstOptions?.map(({ label, value, selected }) => (
          <Fragment key={`${name}_${value}`}>
            {value && (
              <label className="filterOption">
                {toggle ? (
                  <Toggle
                    variant="md"
                    category="secondary"
                    value={value}
                    checked={selected}
                    onChange={handleOnChangeProductTypeFilter}
                    data-ic-trigger="filtros-categoria"
                  />
                ) : (
                  <S.FilterItemInputCheckbox
                    type="checkbox"
                    value={value}
                    onChange={handleOnChangeProductTypeFilter}
                    defaultChecked={selected}
                    data-ic-trigger="filtros-categoria"
                    data-testid="checkboxFilter"
                  />
                )}
                {label}
              </label>
            )}
          </Fragment>
        ))}
        {showingRemaining &&
          remainingOptions?.map(({ label, value, selected }) => (
            <Fragment key={`${name}_${value}`}>
              {value && (
                <label className="filterOption">
                  {toggle ? (
                    <Toggle
                      variant="md"
                      category="secondary"
                      value={value}
                      checked={selected}
                      onChange={handleOnChangeProductTypeFilter}
                      data-ic-trigger="filtros-categoria"
                    />
                  ) : (
                    <S.FilterItemInputCheckbox
                      type="checkbox"
                      value={value}
                      onChange={handleOnChangeProductTypeFilter}
                      defaultChecked={selected}
                      data-ic-trigger="filtros-categoria"
                    />
                  )}
                  {label}
                </label>
              )}
            </Fragment>
          ))}
        {remainingOptions.length > 0 && (
          <span
            className="filterExpand"
            onClick={() => setshowingRemaining(!showingRemaining)}
          >
            {!showingRemaining ? 'Ver mais' : 'Ver menos'}
          </span>
        )}
      </S.FilterItemOptions>
    </S.FilterItem>
  )
}

export default FilterItem
