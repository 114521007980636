import styled from 'styled-components'

import { LinkItemStyledProps } from './QuickCategoryLinks.types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xs} 0 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    margin: ${({ theme }) => theme.spacing.xs} 0;
  }
`

export const Title = styled.h3`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
  ${({ theme }) => theme.font(20, 30, 700)}
  color: ${({ theme }) => theme.colors.black(800)};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`

export const WrapperLinks = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.spacing.xxxs};
  ${({ theme }) =>
    theme.scrollbar(11, theme.colors.black(400), theme.colors.black(500))}
`

export const ListContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  gap: ${({ theme }) => theme.spacing.sm};
`

export const LinkItem = styled.a<LinkItemStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ theme }) => theme.rem(102)};
  width: max-content;
  padding: ${({ theme }) => theme.spacing.xxs};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme, filled }) =>
    filled ? theme.colors.white(500) : theme.colors.secondary(500)};
  background: ${({ theme, filled }) =>
    filled ? theme.colors.secondary(500) : 'transparent'};
  border: 1px solid ${({ theme }) => theme.colors.secondary(500)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.font(14, 18, 600)}

  &:hover {
    background: ${({ theme }) => theme.colors.secondary(500)};
    color: ${({ theme }) => theme.colors.white(500)};
  }
`
