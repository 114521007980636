export function base64Encode(unencoded: string) {
  if (!unencoded || unencoded === '{}') {
    return ''
  }
  return Buffer.from(unencoded).toString('base64')
}

export function base64Decode(encoded: string) {
  return Buffer.from(encoded || '', 'base64').toString()
}
