import styled from 'styled-components'

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SortWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  gap: 0 ${({ theme }) => theme.spacing.xxs};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding-bottom: ${({ theme }) => theme.spacing.sm};
  }
`

export const Limit = styled.label`
  display: none;
  ${({ theme }) => theme.font(14, 18, 700)};
  color: ${({ theme }) => theme.colors.black(800)};
  margin-left: ${({ theme }) => theme.spacing.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.font(14, 18, 700)};
  }
`

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font(12, 16, 400)};
  color: ${(props) => props.theme.colors.black(800)};
  overflow-x: auto;
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  div,
  a,
  b {
    width: max-content;
    display: flex;
  }

  b {
    ${({ theme }) => theme.font(12, 16, 600)};
  }

  span,
  a {
    padding: 0 ${({ theme }) => theme.spacing.xxxs};
    text-decoration: none;
    ${({ theme }) => theme.font(12, 16, 400)};
    color: ${(props) => props.theme.colors.black(800)};
  }

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    padding-top: ${({ theme }) => theme.spacing.sm};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: none;

    > b {
      ${({ theme }) => theme.font(12, 16, 700)};
    }

    span,
    a {
      ${({ theme }) => theme.font(14, 18, 400)};
    }
  }
`

export const Filters = styled.div`
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    grid-template-columns: repeat(3, 3fr) 1fr;
  }
`

export const OpenFilters = styled.div`
  display: flex;
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: none;
  }
`

export const Toggle = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Listing = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    margin-right: ${({ theme }) => theme.spacing.lg};
  }
`

export const CatalogCount = styled.div`
  display: flex;
  ${({ theme }) => theme.font(14, 18, 400)};
  color: ${({ theme }) => theme.colors.black(700)};
  margin: ${({ theme }) => theme.spacing.xs} 0;

  b {
    ${({ theme }) => theme.font(14, 18, 700)};
    margin-right: ${({ theme }) => theme.spacing.xxxs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    align-self: center;
    justify-content: center;
    margin: 0;
  }
`

export const ProductsGrid = styled.main`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    grid-template-columns: repeat(5, minmax(200px,1fr));
  }
`

export const ProductsList = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.sm};
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.lg} 0;

  .pagination {
    display: flex;

    .page,
    .nextLink,
    .prevLink,
    .break {
      margin: 0 ${({ theme }) => theme.spacing.xxs};
      color: ${(props) => props.theme.colors.black(550)};
      ${({ theme }) => theme.font(16, 28, 700)};
    }

    .active {
      color: ${(props) => props.theme.colors.secondary(500)};
    }
  }
`

export const Page = styled.button`
  background: transparent;
  ${({ theme }) => theme.font(16, 28, 700)};
  color: ${({ theme }) => theme.colors.black(550)};
`

export const ListingDescriptions = styled.section`
  color: ${({ theme }) => theme.colors.black(700)};

  p {
    ${({ theme }) => theme.font(14, 24)}
    margin-bottom: ${({ theme }) => theme.spacing.xs}
  }

  h1,
  h2 {
    ${({ theme }) => theme.font(14, 18, 700)}
    margin-bottom: ${({ theme }) => theme.spacing.xxxs}
  }

  a {
    text-decoration: none;
    ${({ theme }) => theme.font(14, 24, 500)}
    color: ${({ theme }) => theme.colors.secondary(500)};
  }
`

export const ListingFaq = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs};
  margin: ${({ theme }) => theme.spacing.md} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    margin: ${({ theme }) => theme.spacing.lg} 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    margin: ${({ theme }) => theme.spacing.xl} 0;
  }
`

export const FaqItem = styled.div`
  background-color: ${({ theme }) => theme.colors.white()};
  padding: ${({ theme }) => theme.spacing.sm};

  svg {
    width: ${({ theme }) => theme.rem(14)};
    height: ${({ theme }) => theme.rem(14)};
  }

  h1,
  h2 {
    ${({ theme }) => theme.font(12, 16, 700)}
    color: ${({ theme }) => theme.colors.black(800)};
    padding: 0;
  }
`

export const FaqItemContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};

  * {
    ${({ theme }) => theme.font(12, 16)}
    color: ${({ theme }) => theme.colors.black(700)};
  }
`

export const ToggleView = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
  }

  button + button {
    margin-left: ${({ theme }) => theme.spacing.sm};
  }
`

export const BannerFooter = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`
