export const sortOptions = {
  catalog: [
    {
      value: '',
      label: 'Escolha'
    },
    {
      value: 'price',
      label: 'Preço crescente'
    },
    {
      value: '-price',
      label: 'Preço decrescente'
    },
    {
      value: '-number_ratings',
      label: 'Mais avaliados'
    },
    {
      value: '-date_product_arrived',
      label: 'Mais recentes'
    },
    {
      value: 'most_searched',
      label: 'Mais procurados'
    },
    {
      value: 'manufacturer_name',
      label: 'Fabricante'
    },
    {
      value: 'offer_price',
      label: 'Promoções'
    }
  ],
  smarthint: [
    {
      value: '',
      label: 'Escolha'
    },
    { value: 'most_searched', label: 'Relevância' },
    {
      value: 'price',
      label: 'Preço crescente'
    },
    {
      value: '-price',
      label: 'Preço decrescente'
    },
    {
      value: 'alphabetic_az',
      label: 'Alfabética A-Z'
    },
    {
      value: 'alphabetic_za',
      label: 'Alfabética Z-A'
    },
    {
      value: '-date_product_arrived',
      label: 'Mais recentes'
    },
    {
      value: 'best_sellers',
      label: 'Mais Vendidos'
    },
    {
      value: 'most_seen',
      label: 'Mais Vistos'
    },
    {
      value: 'discount',
      label: 'Maior Desconto'
    }
  ]
}
