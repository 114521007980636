import { ChangeEvent } from 'react'

import { dataLayerEventFilter } from '@k_frontend/core'
import { useCatalog } from 'hooks/useCatalog'
import { SEARCH_VARIANT } from 'settings/kernel'

import { limitOptions } from './Limit.constants'
import * as S from './Limit.styles'
import { LimitProps as Props } from './Limit.types'

function Limit(props: Props) {
  const { params, catalog } = useCatalog()
  const searchVariant = catalog?.meta?.searchVariant || SEARCH_VARIANT
  const { onChangeLimit } = props

  const handleOnChangeLimit = (e: ChangeEvent<HTMLSelectElement>) => {
    const limit = e.target.value

    dataLayerEventFilter('categorias-limite-exibir', limit)

    onChangeLimit(Number(limit))
  }

  return (
    <S.Limit onChange={handleOnChangeLimit} value={params.page_size}>
      {limitOptions[searchVariant]?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </S.Limit>
  )
}

export default Limit
