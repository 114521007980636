import { useCallback, useEffect, useRef } from 'react'

function useDebouncedCallback(delay: number = 500) {
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  const callbackRef = useRef<(...args: any[]) => void>(() => {})
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const setDebouncedCallback = useCallback(
    (newCallback: (...args: any[]) => void) => {
      callbackRef.current = newCallback

      if (timerRef.current) clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        if (callbackRef.current) {
          callbackRef.current()
        }
      }, delay)
    },
    [delay]
  )

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  return { setDebouncedCallback }
}

export { useDebouncedCallback }
