import styled from 'styled-components'

export const SelectedFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  margin: ${({ theme }) => theme.spacing.xs} 0;

  border-bottom: 1px solid #b6bbc2;
`

export const Filter = styled.div`
  ${({ theme }) => theme.font(10, 16)};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xxxs}
    ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${(props) => props.theme.colors.secondary(500)};
  color: ${(props) => props.theme.colors.secondary(500)};

  svg {
    margin-left: ${({ theme }) => theme.spacing.xxs};
    cursor: pointer;
  }
`
