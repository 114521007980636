import { useState } from 'react'

import { IconFilter } from '@k_frontend/icons'
import HighlightedFilter from 'components/HighlightedFilter'
import FilterItem from 'components/PageCatalog/FilterItem'
import { useCatalog } from 'hooks/useCatalog'
import { clearObject } from 'utils/functions'

import { ResizeHOC } from 'components/ResizeHOC'
import { useDebouncedCallback } from 'hooks/useDebouncedCallback'
import { HIGHLIGHTED_FILTERS } from './Filter.constants'
import * as S from './Filter.styles'
import { FilterProps, FilterTypes, HighlightedFilters } from './Filter.types'

export default function Filter({
  metaFilters,
  setIsFiltersOpen,
  cookieIsMobile
}: FilterProps) {
  const [key, setKey] = useState(0)
  const { filters, setFilters } = useCatalog()
  const { setDebouncedCallback } = useDebouncedCallback()
  const showFilters = metaFilters?.filters?.length > 0

  const hasOfferFilter = !!metaFilters.filters?.find(
    ({ name, values }) =>
      name === FilterTypes.HAS_OFFER && values.some((value) => value === true)
  )

  const hasPriceFilters = !!metaFilters.filters.find(
    (filter) => filter.name === FilterTypes.PRICE
  )

  const personalizedFilters = metaFilters.filters?.filter(
    (filter) =>
      !Object.values({ ...FilterTypes, ...HighlightedFilters }).includes(
        filter.name as FilterTypes
      )
  )
  const catalogStampFilters = metaFilters.filters.find(
    (catalogFilter) => catalogFilter.name === HighlightedFilters.STAMPS
  )

  const highlightedFilters = HIGHLIGHTED_FILTERS.filter((highlightedFilter) =>
    metaFilters.filters.find(
      (catalogFilter) => catalogFilter.name === highlightedFilter.type
    )
  )

  const showHighlightedFiltersWrapper =
    !!catalogStampFilters || !!highlightedFilters?.length

  function onSelectFilterPrice(min: number, max: number) {
    setDebouncedCallback(() => {
      setFilters({ ...filters, price: { min, max } })
    })
  }

  function onSelectFilterProductType(type: string, isSelected: boolean) {
    setDebouncedCallback(() => {
      setFilters(clearObject({ ...filters, [type]: [String(isSelected)] }))
    })
  }

  function onSelectFilterDefault(type: string, selected: string) {
    let newFilters = { ...filters }
    const filter = newFilters?.[type] || []

    const alreadySelected = filter.includes(selected)
    if (alreadySelected) {
      newFilters = {
        ...newFilters,
        [type]: filter.filter((item) => item !== selected)
      }
    } else {
      filter.push(selected)
      newFilters = { ...newFilters, [type]: filter }
    }

    setDebouncedCallback(() => {
      setFilters({ ...clearObject(newFilters) })
    })
  }

  function handleApplyFilter() {
    if (setIsFiltersOpen) setIsFiltersOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function handleClearFilter() {
    if (Object.keys(filters).length > 0) {
      setDebouncedCallback(() => {
        setFilters({})
      })
      setKey((prevKey) => prevKey + 1)
    }
  }

  if (showFilters) {
    return (
      <S.Filter id='asideFilters' key={key}>
        <ResizeHOC
          DesktopComponent={() => null}
          MobileComponent={() => (
            <S.HeaderFilter>
              <span>
                <IconFilter />
                Filtrar por:
              </span>
              <S.CleanFilter onClick={handleClearFilter}>
                Limpar filtros
              </S.CleanFilter>
            </S.HeaderFilter>
          )}
          isMobileScreen={cookieIsMobile}
        />

        <S.FiltersContainer>
          <FilterItem
            onSelectFilterProductType={onSelectFilterProductType}
            filter={{
              name: 'Vendido por',
              values: [
                {
                  value: FilterTypes.KABUM_PRODUCT,
                  label: 'KaBuM!',
                  selected: filters?.[FilterTypes.KABUM_PRODUCT]?.[0] === 'true'
                }
              ]
            }}
          />

          {showHighlightedFiltersWrapper && (
            <S.HighlightedFilterWrapper>
              {!!catalogStampFilters &&
                catalogStampFilters.values.map((stampFilter) => (
                  <HighlightedFilter
                    key={stampFilter}
                    label={stampFilter}
                    onClick={() =>
                      onSelectFilterDefault(
                        HighlightedFilters.STAMPS,
                        stampFilter
                      )
                    }
                    checked={filters?.[HighlightedFilters.STAMPS]?.includes(
                      stampFilter
                    )}
                  />
                ))}
              {highlightedFilters.map((highlightedFilter) => (
                <HighlightedFilter
                  key={highlightedFilter.label}
                  label={highlightedFilter.label}
                  icon={highlightedFilter.icon}
                  onClick={() =>
                    onSelectFilterProductType(
                      highlightedFilter.type,
                      !JSON.parse(
                        filters?.[highlightedFilter.type]?.[0] || 'false'
                      )
                    )
                  }
                  checked={filters?.[highlightedFilter.type]?.[0] === 'true'}
                />
              ))}
            </S.HighlightedFilterWrapper>
          )}

          <S.BasicFiltersWrapper>
            {hasPriceFilters && (
              <FilterItem
                onSelectFilterPrice={onSelectFilterPrice}
                filter={{
                  name: 'Preço',
                  subtitle: 'Intervalo de Preço',
                  min: metaFilters.filters.find(
                    (filter) => filter.name === FilterTypes.PRICE
                  )?.min,
                  max: metaFilters.filters.find(
                    (filter) => filter.name === FilterTypes.PRICE
                  )?.max,
                  selectedMin: filters?.price?.min,
                  selectedMax: filters?.price?.max
                }}
              />
            )}

            {hasOfferFilter && (
              <FilterItem
                onSelectFilterProductType={onSelectFilterProductType}
                filter={{
                  name: 'Ofertas',
                  values: [
                    {
                      value: FilterTypes.HAS_OFFER,
                      label: 'Oferta',
                      selected: filters?.[FilterTypes.HAS_OFFER]?.[0] === 'true'
                    }
                  ]
                }}
              />
            )}

            {metaFilters.filters
              ?.filter((filter) => filter.name === FilterTypes.MANUFACTURER)
              .map((filter) => (
                <FilterItem
                  type={FilterTypes.MANUFACTURER}
                  onSelectFilterDefault={onSelectFilterDefault}
                  key={FilterTypes.MANUFACTURER}
                  filter={{
                    name: 'Marcas',
                    values: filter.values
                      .sort((a, b) => a.localeCompare(b))
                      .map((value) => ({
                        value,
                        label: value,
                        selected: Boolean(
                          filters?.[FilterTypes.MANUFACTURER]?.includes(value)
                        )
                      }))
                  }}
                />
              ))}
            {metaFilters.filters
              ?.filter((filter) => filter.name === FilterTypes.CATEGORY)
              .map((filter) => (
                <FilterItem
                  type={FilterTypes.CATEGORY}
                  onSelectFilterDefault={onSelectFilterDefault}
                  key={FilterTypes.CATEGORY}
                  filter={{
                    name: 'Categorias',
                    values: filter.values
                      .sort((a, b) => a.localeCompare(b))
                      .map((value) => ({
                        value,
                        label: value,
                        selected: Boolean(
                          filters?.[FilterTypes.CATEGORY]?.includes(value)
                        )
                      }))
                  }}
                />
              ))}
            {personalizedFilters.map((filter) => {
              if (!!filter.name && !!filter?.values[0]) {
                return (
                  <FilterItem
                    type={filter.name}
                    onSelectFilterDefault={onSelectFilterDefault}
                    key={filter.name}
                    filter={{
                      name: filter.name,
                      values: filter.values
                        .sort((a, b) => a.localeCompare(b))
                        .map((value) => ({
                          value,
                          label: value,
                          selected: Boolean(
                            filters?.[filter.name]?.includes(value)
                          )
                        }))
                    }}
                  />
                )
              }
              return null
            })}
          </S.BasicFiltersWrapper>
        </S.FiltersContainer>
        <ResizeHOC
          DesktopComponent={() => null}
          MobileComponent={() => (
            <S.ButtonApplyFilter onClick={handleApplyFilter}>
              Aplicar filtro
            </S.ButtonApplyFilter>
          )}
          isMobileScreen={cookieIsMobile}
        />
      </S.Filter>
    )
  } else {
    return null
  }
}
