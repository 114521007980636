import styled from 'styled-components'

import { ContainerAsideStyledProps } from '../../PageCatalog.types'

export const ContainerSkeleton = styled.div`
  padding: ${({ theme }) => theme.spacing.xs};
`

export const ContainerAside = styled.div<ContainerAsideStyledProps>`
  position: absolute;
  flex-direction: column;
  overflow: hidden;
  top: ${({ theme, showAddresses }) =>
    showAddresses ? theme.rem(132) : theme.rem(96)};
  left: 0;
  width: 85vw;
  bottom: 0;
  background: ${({ theme }) => theme.colors.black(300)};
  z-index: 3;
`

export const BackgroundContainerAside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh);
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.colors.white(500)};
  ${({ theme }) => theme.font(14, 24)};
  color: ${({ theme }) => theme.colors.black(800)};

  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`
