const IconClose = (props) => (
  <svg
    width={props.size || 12}
    height={props.size || 12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M9 3L3 9"
      stroke={props.color || '#FF6500'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3L9 9"
      stroke={props.color || '#FF6500'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { IconClose as default }
