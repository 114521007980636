import { ChangeEvent } from 'react'

import { dataLayerEventFilter } from '@k_frontend/core'
import { IconSort } from '@k_frontend/icons'
import { useCatalog } from 'hooks/useCatalog'
import { SEARCH_VARIANT } from 'settings/kernel'

import { ResizeHOC } from 'components/ResizeHOC'
import { sortOptions } from './Sort.constants'
import * as S from './Sort.styles'
import { SortProps } from './Sort.types'

export default function Sort({ onChangeSort, cookieIsMobile }: SortProps) {
  const handleOnChangeSort = (e: ChangeEvent<HTMLSelectElement>) => {
    const sort = e.target.value
    dataLayerEventFilter('categorias-ordenar', sort)
    onChangeSort(sort)
  }
  const { params, catalog } = useCatalog()
  const searchVariant = catalog?.meta?.searchVariant || SEARCH_VARIANT

  function renderText() {
    return <S.Ordination>Ordenar:</S.Ordination>
  }

  return (
    <S.Container>
      <IconSort className='IconSort' />
      <ResizeHOC
        MobileComponent={() => null}
        DesktopComponent={renderText}
        isMobileScreen={cookieIsMobile}
      />
      <S.Sort onChange={handleOnChangeSort} value={params.sort}>
        {sortOptions[searchVariant]?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </S.Sort>
    </S.Container>
  )
}
