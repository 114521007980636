import styled from 'styled-components'

import { Category } from './types'

type ToggleStyledProps = {
  width: number
  height: number
  thumb: number
  top: number
  category: Category
}

export const Toggle = styled.input<ToggleStyledProps>`
  appearance: none;
  position: relative;
  background: ${(props) => props.theme.colors.black(550)};

  width: ${(props) => props.theme.rem(props.width)};
  height: ${(props) => props.theme.rem(props.height)};

  border-radius: ${(props) => props.theme.rem(props.thumb / 2)};
  cursor: pointer;
  transition: background 200ms ease, left 200ms ease;
  will-change: background, left;

  &::after {
    position: absolute;
    content: '';
    width: ${(props) => props.theme.rem(props.thumb)};
    height: ${(props) => props.theme.rem(props.thumb)};
    background: ${(props) => props.theme.colors.black(400)};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: ${(props) => props.theme.borderRadius.circle};
    left: ${(props) => props.theme.rem(-2)};
    top: ${(props) => props.theme.rem(props.top)};
    transition: background 200ms ease, left 200ms ease;
    will-change: background, left;
  }

  &:checked {
    background: ${(props) => props.theme.colors[props.category](300)};

    &::after {
      background: ${(props) => props.theme.colors[props.category](500)};
      left: ${(props) => props.theme.rem(props.width - props.thumb)};
    }
  }

  &:disabled {
    background: ${(props) => props.theme.colors.black(400)};

    &::after {
      background: ${(props) => props.theme.colors.black(400)};
    }
  }
`
