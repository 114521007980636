import { FilterType, ListingTypes, Params, Product } from 'types/catalog'
import { base64Encode } from 'utils/base64'
import { SetState, create } from 'zustand'
import { devtools } from 'zustand/middleware'

import {
  CatalogState,
  CatalogWithDataIntersection,
  Filter,
  InitialState
} from './types'

const setFilters = (set: SetState<CatalogState>, filters: any): void =>
  set((state) => ({
    ...state,
    filters,
    params: {
      ...state.params,
      page_number: 1,
      facet_filters: base64Encode(JSON.stringify(filters))
    }
  }))

const useCatalog = create<CatalogState>()(
  devtools(
    (set) => ({
      loading: false,
      products: [],
      catalog: undefined,
      params: {
        query: '',
        page_number: 1,
        page_size: 20,
        facet_filters: '',
        sort: '',
        is_prime: false
      },
      filters: undefined,
      filterPressed: FilterType.DEFAULT,
      listingType: ListingTypes.LISTING,
      init: (initialState: InitialState) =>
        set((state) => ({ ...state, ...initialState })),
      setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
      setParams: (params: Params, filterPressed: string = FilterType.DEFAULT) =>
        set((state) => ({ ...state, params, filterPressed })),
      setProducts: (products: Product[]) =>
        set((state) => ({ ...state, products })),
      setCatalog: (catalog: CatalogWithDataIntersection) =>
        set((state) => ({ ...state, catalog })),
      setFilters: (filter: Filter) => setFilters(set, filter)
    }),
    {
      name: 'useCatalog'
    }
  )
)

export { useCatalog }
