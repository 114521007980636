import styled from 'styled-components'

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FiltersContainer = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xxs} 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow-y: scroll;
  overscroll-behavior: contain;

  > details {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    height: auto;
    overflow: visible;
  }
`

export const BasicFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`

export const ButtonApplyFilter = styled.button`
  display: flex;
  justify-content: center;
  width: ${({ theme }) => theme.rem(236)};
  padding: ${({ theme }) => theme.spacing.xxs};
  margin: ${({ theme }) => theme.spacing.sm} auto;
  background: ${({ theme }) => theme.colors.secondary(500)};
  color: ${({ theme }) => theme.colors.white(500)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`

export const HeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings('xxs', 'md')};
  background-color: ${({ theme }) => theme.colors.white(500)};

  > span {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.font(12, 16, 600)};
    color: ${({ theme }) => theme.colors.black(800)};

    svg {
      margin-right: ${({ theme }) => theme.spacing.xxs};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding: ${({ theme }) => theme.spacings('xxs', 'md', 'xxs', 'xxxs')};

    > span {
      padding: 0;
      ${({ theme }) => theme.font(16, 28, 700)};
    }
  }
`

export const CleanFilter = styled.button`
  background: transparent;
  ${({ theme }) => theme.font(12, 16, 400, 'uppercase')};
  color: ${({ theme }) => theme.colors.black(600)};
`

export const HighlightedFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.white()};
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`
