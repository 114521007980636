import styled from 'styled-components'

export const FilterItem = styled.details`
  width: 100%;
  background: ${({ theme }) => theme.colors.white()};
  padding-bottom: ${({ theme }) => theme.spacing.xxs};

  &[open] {
    summary {
      &::after {
        transform: rotate(0deg);
      }
    }
  }

  summary {
    ${({ theme }) => theme.font(14, 18, 600)};
    position: relative;
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing.xxxs} ${theme.spacing.sm}`};
    padding-right: ${({ theme }) => theme.rem(40)};
    color: ${({ theme }) => theme.colors.black(700)};
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      width: ${({ theme }) => theme.rem(16)};
      height: ${({ theme }) => theme.rem(10)};
      right: ${({ theme }) => theme.rem(16)};
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADmSURBVHgBtZbRCcIwFEXvLfjvCI7QFbqJP4Kfuk3BHzfQDbqCIzhC/wXry2spAbVNXpILgbR55JwmJQRIyLDH1jUkhDBGwRt0+vBCwyt6GGIS8OD19OphlYgW+AFHikSUwALcLBEsEAA3SQQJ/IUTz7EAOxglVgUW4RUa7b9l3CjBFDjbcQWGo8CNEkyFz/VGCeaAp0gwF9wqwZxwiwRzw2MlWAIeI8FS8FCJSuC3UnCdqp0OLH7NVQv7XknnLK0vAV+RcMzT+A8cdAU6Kehzw/142+FuUQ0vsg3zoEhoQeE4xvTBmg+cALiWpmCWrwAAAABJRU5ErkJggg==');
      background-size: ${({ theme }) => theme.spacing.sm};
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      padding: ${({ theme }) =>
        `${theme.spacing.xs} ${theme.spacing.lg} ${theme.spacing.xxxs} ${theme.spacing.sm}`};
    }
  }
`

export const FilterItemSubtitle = styled.p`
  ${({ theme }) => theme.font(14, 24)};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.black(700)};
`

export const FilterItemOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.xxxs} ${theme.spacing.sm}`};

  label {
    ${({ theme }) => theme.font(14, 24)};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black(700)};
    cursor: pointer;
  }

  input {
    margin-right: ${({ theme }) => theme.spacing.xxs};
    flex-shrink: 0;
  }

  > span {
    ${({ theme }) => theme.font(12, 18, 400)};
    color: ${({ theme }) => theme.colors.secondary(500)};
    cursor: pointer;
    padding: ${({ theme }) => theme.rem(6)} 0;
  }
`

export const FilterItemInputCheckbox = styled.input`
  -webkit-appearance: none;
  background-color: transparent;
  border: ${({ theme }) => theme.rem(1)} solid
    ${({ theme }) => theme.colors.black(700)};
  width: ${({ theme }) => theme.rem(16)};
  height: ${({ theme }) => theme.rem(16)};
  position: relative;
  transition: border-color 150ms ease;

  &::after {
    position: absolute;
    display: flex;
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBhc7RDUAwEMbx/wl7GIVJeBAxkkcmMYruQZy2ElEavZc2d798d8JP6UDJweL+WRKpfRUkicCwUYv2rFzZtYyYKJowmYv1TTvUjiqGXI5oa5uFP7h8bA+Qh/6mEH/QDW+c07Azv5GrE4MoOCeYLx78AAAAAElFTkSuQmCC');
    width: ${({ theme }) => theme.rem(16)};
    height: ${({ theme }) => theme.rem(16)};
    bottom: ${({ theme }) => theme.rem(5)};
    left: ${({ theme }) => theme.rem(2)};
    opacity: 0;
    transition: opacity 150ms ease, transform 150ms ease;
    transform: scale(0);
  }

  &:checked {
    border-color: ${({ theme }) => theme.colors.secondary(500)};

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const SearchBrands = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xxs};

  svg {
    position: absolute;
    top: 50%;
    left: ${({ theme }) => theme.spacing.sm};
    transform: translate(0, -50%);
  }
`

export const InputBrands = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  padding-left: ${({ theme }) => theme.rem(42)};
  ${({ theme }) => theme.font(14, 18)}
  border: 1px solid ${({ theme }) => theme.colors.black(600)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`
