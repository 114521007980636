import Categories from 'components/PageCatalog/Categories'
import Filter from 'components/PageCatalog/Filter'

import { ResizeHOC } from 'components/ResizeHOC'
import { CategoriesResizeProps } from '../../PageCatalog.types'
import * as S from './AsideFiltersDesktop.styles'
import { AsideFiltersDesktopProps } from './AsideFiltersDesktop.types'

export default function AsideFiltersDesktop({
  catalog,
  categories,
  cookieIsMobile,
  isLoggedIn
}: AsideFiltersDesktopProps) {
  const { meta } = catalog ?? {}
  return (
    <>
      <S.ContainerAside id='listingAsideDesktop' showAddresses={isLoggedIn}>
        {categories && (
          <ResizeHOC<CategoriesResizeProps>
            DesktopComponent={Categories}
            MobileComponent={() => null}
            categories={categories}
            isMobileScreen={cookieIsMobile}
          />
        )}
        <Filter metaFilters={meta} cookieIsMobile={cookieIsMobile} />
      </S.ContainerAside>
    </>
  )
}
