import styled from 'styled-components'

export const Limit = styled.select`
  flex: 1;
  ${({ theme }) => theme.font(14, 24)};
  background-color: ${(props) => props.theme.colors.white(500)};
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC2SURBVHgBpc8xCsIwFAbg/6Xo7OjWdtDBySNEUXGokCPoSexRvIKDk2DxBK4ioj1CRgfbZ1tRUjWl4D9kCPn+vAdkkVK1UDPmWyHHs7loJgc5VV4dSI17JCdBWGDB7ALsiTSJqgpekAj9pwGc6+W09/0uQFDErNxObx2fj9oGwbzabTeLAudHVYEN5iHzh+EoWEIgzK7jVDgD3KBt8At/FjCztsH32GZKKxDaNvgTlwrAsQ3+nQeY02Y0uvQN4QAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-position: center right ${({ theme }) => theme.spacing.sm};
  border-radius: ${(props) => props.theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  padding-right: ${({ theme }) => theme.spacing.xl};
  margin-left: ${({ theme }) => theme.spacing.xxs};
  color: ${({ theme }) => theme.colors.black(800)};

  /* remove seta padrão */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`
