import styled from 'styled-components'

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.font(14, 24, 400)};
  color: ${(props) => props.theme.colors.black(800)};
  margin-top: ${({ theme }) => theme.spacing.sm};
`
