import { useEffect, useRef, useState } from 'react'

import { dataLayerEventPromotionView } from '@k_frontend/core'
import { Col, Row } from '@k_frontend/ui'
import Categories from 'components/PageCatalog/Categories'
import Products from 'components/PageCatalog/Products'
import useIsVisible from 'hooks/useIsVisible'

import { ResizeHOC } from 'components/ResizeHOC'
import AsideFiltersDesktop from '../AsideFilters/Desktop'
import * as S from './MainContentPageCatalog.styles'
import { MainContentPageCatalogProps } from './MainContentPageCatalog.types'

export default function MainContentPageCatalog({
  dataCatalog,
  loadingProducts,
  isLoggedIn,
  addresses,
  categories,
  onProductClick,
  productPathLink,
  renderBreadcrumb = () => <></>,
  banners,
  breadcrumb,
  bannerFooter,
  cookieIsMobile,
  catalogServer
}: MainContentPageCatalogProps) {
  const [onScreenAsideBanner, setOnScreenAsideBanner] = useState(false)
  const [wasPrintedAsideBanner, setWasPrintedAsideBanner] = useState(false)
  const AsideBannerRef = useRef()

  const asideBannerObserver = useIsVisible(setOnScreenAsideBanner, {
    threshold: 0.75
  })

  if (AsideBannerRef?.current && !wasPrintedAsideBanner) {
    asideBannerObserver?.observe(AsideBannerRef.current)
  }

  function handlePosition(breadcrumb, positionName = 'banner'): string {
    let position = positionName
    if (breadcrumb && breadcrumb.length > 0) {
      position += `-${breadcrumb?.[0].name}`
      if (breadcrumb.length > 1) {
        position += `-${breadcrumb?.[1].name}`
      }
    }
    return position
  }

  function renderAsideBanner() {
    const shouldRenderBanner =
      !!banners && Object?.keys(banners?.sidebar || {})?.length > 0

    if (!shouldRenderBanner) return null

    return (
      <S.ContainerAsideBanner ref={AsideBannerRef}>
        <ResizeHOC
          DesktopComponent={() => (
            <S.AsideBanner className='asideBanner' href={banners.sidebar.link}>
              <img
                src={banners.sidebar.banner}
                alt={banners.sidebar.titulo}
                width={280}
                height={525}
              />
            </S.AsideBanner>
          )}
          MobileComponent={() => null}
          isMobileScreen={cookieIsMobile}
        />
      </S.ContainerAsideBanner>
    )
  }

  useEffect(() => {
    if (wasPrintedAsideBanner) return
    if (onScreenAsideBanner && !wasPrintedAsideBanner) {
      if (banners) {
        const position = handlePosition(breadcrumb, 'banner-sidebar')
        const bannersFormatted = [
          {
            id: banners?.sidebar?.banner_id || '',
            name: banners?.sidebar?.titulo || '',
            creative: banners?.sidebar?.creative || '',
            position,
            index: 1
          }
        ]
        dataLayerEventPromotionView(bannersFormatted)
      }
      setWasPrintedAsideBanner(true)
      asideBannerObserver.disconnect()
    }
  }, [onScreenAsideBanner])

  return (
    <div>
      <Row>
        <Col sm={4} md={6} lg={3} xl={2}>
          <S.Aside>
            <ResizeHOC
              DesktopComponent={() => (
                <AsideFiltersDesktop
                  catalog={catalogServer}
                  categories={categories}
                  cookieIsMobile={cookieIsMobile}
                  isLoggedIn={isLoggedIn && addresses?.length > 0}
                />
              )}
              MobileComponent={() => null}
              isMobileScreen={cookieIsMobile}
            />
            {renderAsideBanner()}
          </S.Aside>
        </Col>
        <Col sm={4} md={6} lg={9} xl={10} style={{ alignContent: 'start' }}>
          <Products
            onProductClick={onProductClick}
            descriptions={categories?.seo}
            faq={categories?.faq}
            catalog={dataCatalog}
            loadingProducts={loadingProducts}
            productPathLink={productPathLink}
            renderBreadcrumb={renderBreadcrumb}
            bannerFooter={bannerFooter}
            cookieIsMobile={cookieIsMobile}
            isLoggedIn={isLoggedIn && addresses?.length > 0}
          />
          <ResizeHOC
            MobileComponent={() => <Categories categories={categories} />}
            DesktopComponent={() => null}
            isMobileScreen={cookieIsMobile}
          />
        </Col>
      </Row>
    </div>
  )
}
