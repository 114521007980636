import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing.sm};
`

export const Title = styled.span`
  ${(props) => props.theme.font(16, 24, 700)};
  color: ${(props) => props.theme.colors.black(800)};
  margin-bottom: ${(props) => props.theme.spacing.sm};
`

export const ListTitle = styled.span`
  ${(props) => props.theme.font(14, 24, 700)};
  color: ${(props) => props.theme.colors.black(700)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    flex-direction: column;
  }
`

export const List = styled.div<{ showMore: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    flex-direction: column;

    > div:nth-child(n + 6) {
      display: ${(props) => (props.showMore ? 'block' : 'none')};
    }
  }
`

export const Item = styled.div`
  white-space: pre-wrap;
  &:not(:last-child):after {
    ${(props) => props.theme.font(12, 32)};
    color: ${(props) => props.theme.colors.black(700)};
    content: ' - ';
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    &:not(:last-child):after {
      content: none;
    }
    &:before {
      content: '   ';
    }
  }
`

export const ItemText = styled.a`
  ${(props) => props.theme.font(12, 32)};
  color: ${(props) => props.theme.colors.black(700)};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${(props) => props.theme.font(14, 24)};
  }
`

export const ButtonShowMore = styled.button`
  ${(props) => props.theme.font(12, 32)};
  display: flex;
  color: ${({ theme }) => theme.colors.secondary()};
  background: transparent;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${(props) => props.theme.font(14, 24)};
    margin-top: ${({ theme }) => theme.spacing.xxxs};
  }
`
