import * as S from './styles'
import { ToggleProps as Props, Sizes } from './types'

function Toggle(props: Props) {
  const sizes: Sizes = {
    md: { w: 24, h: 10, t: 14, top: -2 },
    lg: { w: 34, h: 14, t: 20, top: -3 }
  }

  const { variant, checked, category = 'secondary', onChange, value } = props

  return (
    <S.Toggle
      type="checkbox"
      value={value}
      category={category}
      checked={checked}
      height={sizes[variant].h}
      width={sizes[variant].w}
      thumb={sizes[variant].t}
      top={sizes[variant].top}
      onChange={onChange}
    />
  )
}

export default Toggle
