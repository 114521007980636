import { useEffect, useRef } from 'react'

function useDidUpdateEffect(fn: () => void, dependencies: any[]): void {
  const mountRef = useRef<boolean>(false)

  useEffect((): void => {
    if (mountRef.current) {
      fn()
    } else {
      mountRef.current = true
    }
  }, dependencies)
}

export { useDidUpdateEffect }
