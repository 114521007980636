import * as S from './styles'

const EmptyMessage = (props) => {
  const { subtitle } = props

  return (
    <S.EmptyMessage id="listingEmpty">
      <b>
        Lamentamos, nenhum produto encontrado com esse critério de pesquisa.
      </b>
      {subtitle}
    </S.EmptyMessage>
  )
}

export default EmptyMessage
