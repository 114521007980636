import styled from 'styled-components'

export const Aside = styled.aside`
  width: 100%;
`

export const ContainerAsideBanner = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: flex;
    flex: 1;
  }
`

export const AsideBanner = styled.a`
  margin-top: ${({ theme }) => theme.spacing.lg};

  img {
    object-fit: cover;
    width: ${({ theme }) => theme.rem(280)};
    max-width: 100%;
    height: ${({ theme }) => theme.rem(525)};
  }
`
