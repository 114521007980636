export function QuickCategoryLinksLoading() {
  function generateQuickCategoryButtonLoading(
    quickCategoryButtonQuantity: number
  ) {
    return Array(quickCategoryButtonQuantity)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className='min-w-[102px] h-[36px] rounded-4 animate-pulse bg-black-500'
        />
      ))
  }

  return (
    <div className='flex gap-16 w-full pb-4 overflow-auto scrollbar-hide'>
      {generateQuickCategoryButtonLoading(6)}
    </div>
  )
}
