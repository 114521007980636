import { TextField } from '@k_frontend/ui'
import * as Slider from '@radix-ui/react-slider'
import styled from 'styled-components'

export const Root = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: fit-content;
  padding: ${({ theme }) => theme.spacing.xxs} 0;
`

export const Track = styled(Slider.Track)`
  position: relative;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.black(500)};
  height: ${({ theme }) => theme.rem(3)};
`

export const Range = styled(Slider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white(500)};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary(500)};
`

export const Thumb = styled(Slider.Thumb)`
  display: block;
  width: ${({ theme }) => theme.rem(16)};
  height: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.secondary(500)};
  border: solid 1px ${({ theme }) => theme.colors.secondary(500)};
`

export const Form = styled.form`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxxs};
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(20)};

  & input {
    padding: ${({ theme }) => theme.spacing.xxs};
  }
`

export const Separator = styled.div`
  color: ${({ theme }) => theme.colors.black(600)};
`

export const WrapperLabelValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LabelValue = styled.span`
  ${({ theme }) => theme.font(12, 16)};
  color: ${({ theme }) => theme.colors.black(700)};
`

export const InputRangePrice = styled(TextField)`
  &&& {
    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      input {
        ${({ theme }) => theme.font(12)};
        padding: ${({ theme }) => theme.spacing.xs};
      }
    }
  }
`
